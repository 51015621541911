import { bind } from 'immer-yjs';
import { useContext, useEffect } from 'react';
import { Agent } from '@property-folders/contract/property';
import { bindSetOrUnsetIfUndefined } from '@property-folders/common/util/immerTools';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { useDispatch, useSelector } from 'react-redux';
import { useLightweightTransaction } from './useTransactionField';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { find } from 'lodash';
import {
  BelongingEntityMeta,
  REDUCER_NAME as entityMetaKey,
  REDUCER_NAME as ENTITY_REDUX_KEY
} from '@property-folders/common/redux-reducers/entityMeta';
import { companyTradingWithFallback, parseInt2 } from '@property-folders/common/util/formatting';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import * as Y from 'yjs';
import { depthObservation } from '../form-gen-util/yjsObserver';
import { fullRevalidation } from '@property-folders/common/redux-reducers/validation';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';
import { Predicate } from '@property-folders/common/predicate';
import { TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { metaKeyFromDataKey } from '@property-folders/common/util/dataExtract';

export function useForm() {
  const dispatch = useDispatch();
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formName, transactionRules, formRules, formId, printTitle, wizardTitle } = useContext(FormContext);
  const formFamilyCode = FormTypes[formName]?.formFamily;

  const { value: linkedPrimaryEntity } = useLightweightTransaction({ myPath: 'agent.[0].linkedEntityId' });
  const thisEntitySettings = useSelector(state => state?.[ENTITY_REDUX_KEY]?.[linkedPrimaryEntity] as EntitySettingsEntity | undefined);

  useEffect(()=>{
    if (!ydoc || !linkedPrimaryEntity || !thisEntitySettings?.salespeople) {
      return;
    }

    const binder = bind<any>(ydoc.getMap(transactionRootKey));

    binder.update(draft => {
      const docOwningEntity = draft.agent?.[0] as Agent | undefined;
      if (!docOwningEntity) {
        return;
      }

      const setEntityField = bindSetOrUnsetIfUndefined(docOwningEntity);
      docOwningEntity.company = companyTradingWithFallback(thisEntitySettings.name, thisEntitySettings.tradeName); // Always returns a string
      docOwningEntity.profileName = thisEntitySettings.profileName || docOwningEntity.company;
      setEntityField('abn', canonicalisers.abnacn(thisEntitySettings.abn).canonical);
      setEntityField('rla', thisEntitySettings.rla);
      setEntityField('address', thisEntitySettings.addressDisplay || stringifySaleAddress({ streetAddr_parts: { StreetName: thisEntitySettings.address1, Suburb: thisEntitySettings.suburb, Postcode: thisEntitySettings.postcode, State: thisEntitySettings.state } }));
      setEntityField('email', thisEntitySettings.email);
      setEntityField('phone', thisEntitySettings.phone);

      for (const salesperson of docOwningEntity.salesp??[]) {
        const id = parseInt2(salesperson.linkedSalespersonId);
        const spSource = find(thisEntitySettings.salespeople, sp=>sp.id === id);
        if (spSource) {
          const setSalespField = bindSetOrUnsetIfUndefined(salesperson);
          setSalespField('name', spSource?.name);
          setSalespField('email', canonicalisers.email(spSource?.email).canonical);
          setSalespField('phone', canonicalisers.phone(spSource?.phone).canonical);
          setSalespField('linkedSalespersonId', spSource.id);
        } else {
          console.warn('Removing salesperson on Entity Settings Change');
          // known HMR bug here, will cause Agents section to appear varied.
          // Not known to happen in static builds
          const remI = docOwningEntity.salesp?.findIndex(docSP => parseInt2(docSP.linkedSalespersonId) === id) ?? -1;
          if (remI >=0) docOwningEntity.salesp?.splice(remI,1);

        }
      }
    });
  }, [thisEntitySettings?.salespeople]);

  useEffect(() => {
    if (!ydoc) return;
    if (!docName) return;
    if (!transactionRootKey) return;

    const currentDocMap = ydoc.getMap(transactionRootKey);
    const changeFunction = (evts: Y.YEvent<any>[]) => {
      depthObservation(
        evts,
        dispatch,
        docName,
        transactionRootKey,
        formName,
        transactionRules,
        formRules,
        {
          formCode: formName,
          formId,
          entities: memberEntities
            ? Object.values(memberEntities).filter(Predicate.isNotNull)
            : undefined }
        // depthObservation handles propertyMeta, and uses the ydoc referenced by the event.
        // We wouldn't want to do it here anyway, as that would render the value static at the
        // time the effect was set
      );
    };
    const dispatchFullRevalidation = () => {
      dispatch(fullRevalidation({
        docName,
        mapRoot: transactionRootKey,
        formName,
        dataTree: currentDocMap.toJSON(),
        transactionFieldsDefn: transactionRules,
        formRules,
        context: {
          formCode: formName,
          formId,
          entities: memberEntities
            ? Object.values(memberEntities).filter(Predicate.isNotNull)
            : undefined,
          propertyMeta: ydoc.getMap(metaKeyFromDataKey(transactionRootKey)).toJSON() as TransactionMetaData
        }
      }));
    };

    dispatchFullRevalidation();

    currentDocMap.observeDeep(changeFunction);

    return () => {
      currentDocMap.unobserveDeep(changeFunction);
    };
  }, [ydoc, docName, transactionRootKey, formId, memberEntities]);

  return {
    dispatch,
    ydoc,
    docName,
    transactionRootKey,
    transactionMetaRootKey,
    formName,
    transactionRules,
    formRules,
    formFamilyCode,
    printTitle,
    wizardTitle
  };
}
