import * as Y from 'yjs';
import { Dispatch } from 'redux';
import { fullRevalidation, updateValidationForPath } from '@property-folders/common/redux-reducers/validation';
import { DocumentFieldType, ValidationDefnType } from '@property-folders/contract/yjs-schema/model';
import { ComplexValidationContext } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { metaKeyFromDataKey } from '@property-folders/common/util/dataExtract';
import { TransactionMetaData } from '@property-folders/contract';

export const depthObservation = (evts: Y.YEvent<any>[], dispatch: Dispatch, docName: string, topLevelKey: string, formName: string, transactionFieldsDefn: DocumentFieldType, formRules: ValidationDefnType | undefined, context?: ComplexValidationContext) => {
  evts.forEach(evt => {
    let pathAppend: undefined | string;
    let mustRecalc = false;
    if ([...evt.changes.keys.keys()].length === 0) {
      if (evt.changes.delta.length > 0) {
        let offset = 0;
        let mode = 'unknown';
        for (const change of evt.changes.delta) {
          if (typeof change.retain === 'number' && offset === 0) {
            offset = change.retain;
          } else if (typeof change.retain === 'number') {
            mustRecalc = true;
          } else if (typeof change.delete === 'number' && change.delete === 1 && mode === 'unknown') {
            mustRecalc = true;
            // looks like we don't get ID data for deletions
          } else if (typeof change.delete === 'number') {
            mustRecalc = true;
          } else if (Array.isArray(change.insert) && mode === 'unknown' && change.insert.length === 1) {
            mode = 'insert';
            pathAppend = `[${change.insert[0]?.id ?? offset}]`;
          } else {
            mustRecalc = true;
          }
        }
        if (mode === 'unknown') {
          mustRecalc = true;
        }
      }
    } else if ([...evt.changes.keys.keys()].length === 1) {
      pathAppend = [...evt.changes.keys.keys()][0];
    } else {
      mustRecalc = true;
    }
    const updatedPath = [...evt.path];
    const tree = evt.currentTarget.toJSON();

    const propertyMeta = evt.currentTarget.doc?.getMap(metaKeyFromDataKey(docName)).toJSON() as TransactionMetaData;
    const moreContext = { propertyMeta, ...context };
    if (pathAppend && !mustRecalc) {
      updatedPath.push(pathAppend);
      dispatch(updateValidationForPath({ docName, mapRoot: topLevelKey, formName, dataTree: tree, updatedPath, transactionFieldsDefn, formRules, context: moreContext }));
    } else {
      dispatch(fullRevalidation({ docName, mapRoot: topLevelKey, formName, dataTree: tree, transactionFieldsDefn, formRules, context: moreContext }));
    }

  });
};
