import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ValidationNode,
  buildFocusAndErrorPaths,
  revalidateTransaction
} from '../yjs-schema/property/validation/process-validation';
import {
  DocumentFieldType,
  PathString,
  ValidationDefnType
} from '@property-folders/contract/yjs-schema/model';
import { ComplexValidationContext } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { PathHierarchy } from '../util/pathHandling';
import { FormCode, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { FormTypes } from '../yjs-schema/property/form';
import { FormUtil } from '../util/form';

export const REDUCER_NAME = 'validation';

// Does not work with Arrays as existing parents. The purpose of this
// is to build a nested dictionary structure. A definition would be needed
// if some of the parents need to be made with arrays and then number indexed.
function setWithParents(setPath: string[], setValue: any, setTarget: Record<string, any>) {
  let workingTarget = setTarget;

  for (const pathI in setPath) {
    const key = setPath[pathI];
    if (parseInt(pathI)+1 === setPath.length) {
      if (setValue == null) {
        delete workingTarget[key];
        return;
      }
      workingTarget[key] = setValue;
      return;
    }
    if (!workingTarget[key] || typeof workingTarget[key] !== 'object') {
      workingTarget[key] = {};
    }
    workingTarget = workingTarget[key];
  }
}

type ValidationParams = {
  docName: string,
  mapRoot: string,
  formName: string,
  dataTree: Record<string,any>,
  transactionFieldsDefn: DocumentFieldType,
  formRules?: ValidationDefnType,
  context?: ComplexValidationContext
};

type PathValidationParams = ValidationParams & { updatedPath: (string|number)[] };

export type ValidationReducerState = {
  vtrees: {[ydocName: string]: { [ydocRootKey: string]: {[formCode: string]: ValidationNode}}},
  focus: {[ydocName: string]: { [ydocRootKey: string]: {[formCode: string]: string | undefined}}},
  errorPaths: {[ydocName: string]: { [ydocRootKey: string]: {[formCode: string]: PathHierarchy}}},
  focusErrList: {[ydocName: string]: { [ydocRootKey: string]: {[formCode: string]: PathString[]}}}
};

function maskDataTree({ formId, formCode, data, meta }: {formId?: string, formCode?: string, data: MaterialisedPropertyData | undefined, meta: TransactionMetaData | undefined}): MaterialisedPropertyData | undefined {
  if (!(data && meta && formCode && formId)) return data;
  const formFam = FormTypes[formCode]?.formFamily;
  if (!(formFam && formId && meta?.formStates)) return data;
  const inst = FormUtil.getFormState(formCode, formId, meta);
  if (!inst) return data;

  if (formFam === FormCode.RSAA_SalesAgencyAgreement && inst.unsignedDenormals?.saaVerbosity) {
    return {
      ...data,
      saaSettings: {
        ...data.saaSettings,
        verbosity: inst.unsignedDenormals.saaVerbosity
      }
    };
  }
  return data;
}

// <{vtrees: Record<string,Record<string,Record<string,any>>>}>
const validation = createSlice({
  name: REDUCER_NAME,
  initialState: { vtrees: {}, focus: {}, errorPaths: {}, focusErrList: {} } as ValidationReducerState,
  reducers: {
    fullRevalidation(state, action: PayloadAction<ValidationParams>) {
      const { docName, mapRoot, formName, dataTree: dataTreeParam, transactionFieldsDefn, formRules, context } = action.payload;
      const dataTree = maskDataTree({ formId: context?.formId, formCode: formName, data: dataTreeParam, meta: context?.propertyMeta });
      const vresult = revalidateTransaction(dataTree, transactionFieldsDefn, formRules, context);
      const focuses = buildFocusAndErrorPaths(vresult);
      setWithParents(['errorPaths',docName,mapRoot,formName], focuses.errorPaths, state);
      setWithParents(['focus',docName,mapRoot,formName], focuses.focus, state);
      setWithParents(['focusErrList',docName,mapRoot,formName], focuses.focusErrList, state);
      setWithParents(['vtrees',docName,mapRoot,formName], vresult, state);
    },
    updateValidationForPath(state, action: PayloadAction<PathValidationParams>) {
      const { docName, mapRoot, formName, dataTree: dataTreeParam, transactionFieldsDefn, formRules, updatedPath, context } = action.payload;
      const dataTree = maskDataTree({ formId: context?.formId, formCode: formName, data: dataTreeParam, meta: context?.propertyMeta });
      // This has been de-optimised, because in order to do validations using absolute paths (introduced with
      // _requiredIf), changes to an expected value node won't propogate change to the dependence. Function was called
      // validateChange, as opposed to revalidateTransaction

      setWithParents(['vtrees',docName,mapRoot,formName], revalidateTransaction(dataTree, transactionFieldsDefn, formRules, context), state);
      const focuses = buildFocusAndErrorPaths(state.vtrees[docName][mapRoot][formName]);

      setWithParents(['errorPaths',docName,mapRoot,formName], focuses.errorPaths, state);
      setWithParents(['focus',docName,mapRoot,formName], focuses.focus, state);
      setWithParents(['focusErrList',docName,mapRoot,formName], focuses.focusErrList, state);
    }
  }
});

export const { fullRevalidation, updateValidationForPath } = validation.actions;

export default validation.reducer;
