import { merge } from 'lodash';
import { FormCode, FeeCalcMechanism, SAAVerbosity } from '../../property';
import { globalFieldGroups } from '../field';
import { ExpectedValuePathDefn, ValidationDefnType } from '../validation-field';
import { v4 as uuid } from 'uuid';
import { StandardFieldGroupFnMaps } from '../field-group-function';
import { vendorChildRules } from './common-rules';

const generateShortModeCondition = (): ExpectedValuePathDefn => {
  return {
    notExpectedValue: SAAVerbosity.short,
    field: ['saaSettings', 'verbosity']
  };
};

export const salesAgencyAgreement: ValidationDefnType = {
  _complexValidators: ['feeScalesShouldBeInOrder', 'validateSalespersonStillSalesperson'],
  _atLeastDefns: [{
    focusTarget: 'property-section',
    fields: [['', 'saleAddrs', '[0]', 'streetAddr'], '.saleTitles.[0].title']
  }],
  adminFee: {
    _required: false,
    enable: {
      _prefill: {
        value: true
      }
    },
    strike: {
      _prefill: {
        value: true
      }
    }
  },
  agency: {
    _required: false,
    duration: {
      _prefill: {
        value: 90
      },
      _required: true,
      _validationRequirementFailedMessage: 'Specify Duration of Agreement'
    },
    start: {
      _required: true,
      _prefill: { value: true }
    },
    startOther: {
      _requiredIf: { expectedValue: false, field: ['..', 'start'] }
    }
  },
  agencyExclusive: {
    _required: true,
    _validationRequirementFailedMessage: 'Specify whether Agreement is Sole Agency'
  },
  agent: {
    _children: {
      _required: false,
      linkedEntityId: {
        _required: true
      },
      profileName: {
        _required: false
      },
      salesp: {
        _children: {
          _required: false,
          linkedSalespersonId: {
            _required: true,
            _validationRequirementFailedMessage: 'Salespersons must be correctly linked to the agency'
          },
          name: {
            _required: true,
            _validationRequirementFailedMessage: 'Specify Agent\'s full name'
          }
        },
        _minimum: 1,
        _required: false
      }
    },
    _minimum: 1,
    _required: false
  },
  auction: {
    _required: false,
    onSite: {
      _required: false
    },
    timeTbc: {
      _required: false
    }
  },
  auctionFeeSwitches: {
    applicable: {
      _requiredIf: { expectedValue: 'auction', field: ['..', '..', 'sale', 'saleMethod'] }
    }
  },
  auctionFee: {
    _requiredIf: {
      modeOrTrueAndFalse: false,
      expectations: [
        { expectedValue: 'auction', field: ['..', 'sale', 'saleMethod'] },
        { expectedValue: true, field: ['..', 'auctionFeeSwitches', 'applicable'] }
      ]
    }
  },
  authAssoc: {
    _required: false,
    _prefill: { value: true }
  },
  assocAgents: {
    _required: false,
    _children: {
      company: {
        _requiredIf: {
          expectations: [
            { expectedValue: true, field: ['authAssoc'] },
            { evaluationFunction: 'anySalespersonName', field: ['..', 'salesp'] }
          ]
        }
      }
    }
  },
  cgtEnable: {
    _required: false,
    _prefill: { value: true }
  },
  chattels: {
    _required: true,
    cccEnable: {
      _required: false,
      _prefill: { value: false }
    },
    simple: {
      _required: false,
      _prefill: {
        entries: [
          'Fixed floor coverings',
          'Light fittings',
          'Window treatments',
          'Compliant smoke alarm'
        ]
      }
    }
  },
  clauses: {
    _required: false,
    _children: {},
    _prefill: { entries: [] }
  },
  compareAddrs: {
    _children: {
      addrLine: {
        _requiredIf: {
          expectations: [
            { notExpectedValue: true, field: ['disableCompareAlreadyProvided'] },
            { empty: true, field: ['comparableSales', 'annexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
          ],
          modeOrTrueAndFalse: false
        },
        _prefill: { value: '' }
      },
      soldDate: {
        _requiredIf: {
          expectations: [
            { notExpectedValue: true, field: ['disableCompareAlreadyProvided'] },
            { empty: true, field: ['comparableSales', 'annexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
          ],
          modeOrTrueAndFalse: false

        },
        _prefill: { value: '' }
      },
      soldPrc: {
        _requiredIf: {
          expectations: [
            { notExpectedValue: true, field: ['disableCompareAlreadyProvided'] },
            { empty: true, field: ['comparableSales', 'annexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
          ],
          modeOrTrueAndFalse: false

        },
        _prefill: { value: '' }
      }
    },
    _requiredIf: {
      expectations: [
        { notExpectedValue: true, field: ['disableCompareAlreadyProvided'] },
        { empty: true, field: ['comparableSales', 'annexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
      ],
      modeOrTrueAndFalse: false

    },
    _minimumIf: {
      count: 1,
      expectations: [
        { notExpectedValue: true, field: ['disableCompareAlreadyProvided'] },
        { empty: true, field: ['comparableSales', 'annexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
      ],
      focusTarget: 'comparable-sales-subsection',
      modeOrTrueAndFalse: false
    },
    _prefill: {
      entries: [{ id: uuid(), soldPrc: '', addrLine: '', soldDate: '' }]
    },
    _childErrorFlagFocus: {
      focusTarget: 'comparable-sales-subsection'
    }
  },
  disableCompareAlreadyProvided: {
    _required: false
  },
  encroach: {
    _required: false
  },
  encroachEnable: {
    _required: false
  },
  exclusions: {
    _required: true,
    simple: {
      _required: false,
      _prefill: {
        entries: [
          'Vendor’s and/or occupier’s personal effects',
          'Landline telephones',
          'Loose floor coverings',
          'Furniture',
          'Pot plants and all garden ornaments'
        ]
      }
    }
  },
  gstWithholdEnable: {
    _required: false,
    _prefill: { value: false }
  },
  marketingTemplate: {
    _required: false
  },
  marketingFeesOptions: {
    noFees: {
      _requiredIf: {
        expectations: [
          { isNaN: true, field: ['marketingFeesOptions', 'denormalisedTotalValidation'] },
          { notExpectedValue: true, field: ['marketingFeesOptions', 'noFees'] },
          { empty: true, field: ['marketingFeesOptions', 'externalFeeScheduleAnnexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] },
          { empty: true, field: ['marketingTemplate', 'id'] }
        ],
        modeOrTrueAndFalse: false
      }
    },
    vendorPaidAdvertising: {
      nowAmount: {
        _requiredIf: { expectations: [

          { expectedValue: true, field: ['..', 'vpaPayEnable'] }
        ], modeOrTrueAndFalse: false }
      },
      laterAmount: {
        _requiredIf: { expectations: [

          { expectedValue: true, field: ['..', 'vpaPayEnable'] }
        ], modeOrTrueAndFalse: false }
      }
    }
  },
  marketingFees: {
    _childErrorFlagFocus: {
      focusTarget: 'subsection-marketing-fees'
    },
    _children: {
      itemCost: {
        _requiredIf: {
          expectations: [
            { isNaN: true, field: ['marketingFeesOptions', 'denormalisedTotalValidation'] },
            { notExpectedValue: true, field: ['marketingFeesOptions', 'noFees'] },
            { empty: true, field: ['marketingFeesOptions', 'externalFeeScheduleAnnexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
          ],
          modeOrTrueAndFalse: false
        },
        _validationRequirementFailedMessage: 'Specify item cost'
      },
      itemDesc: {
        _requiredIf: {
          expectations: [
            { empty: false, field: ['..', 'itemCost'] }
          ],
          modeOrTrueAndFalse: false
        },
        _validationRequirementFailedMessage: 'Specify item'
      },
      _minimumIf: {
        count: 1,
        expectations: [
          { isNaN: true, field: ['marketingFeesOptions', 'denormalisedTotalValidation'] },
          { notExpectedValue: true, field: ['marketingFeesOptions', 'noFees'] },
          { empty: true, field: ['marketingFeesOptions', 'externalFeeScheduleAnnexureRef', 'family_'+FormCode.RSAA_SalesAgencyAgreement] }
        ],
        modeOrTrueAndFalse: false
      }
    },
    _prefill: {
      entries: [
        {
          itemDesc: 'Advertising/marketing (and/or adopt Periodic Press Promotion Budget, refer below)'
        },
        {
          itemDesc: 'Electronic marketing (eg internet)'
        },
        {
          itemDesc: 'Brochures, leaflets and mail-out'
        },
        {
          itemDesc: 'Signage and photography'
        },
        {
          itemDesc: 'Sketch/floor plan'
        }
      ]
    },
    _required: false
  },
  notiWorks: {
    _required: false
  },
  notiWorksEnable: {
    _required: false
  },
  vendorWorks: {
    _required: false
  },
  vendorWorksEnable: {
    _required: false
  },
  payTerm: {
    _required: false,
    actualTerms: {
      _prefill: {
        value: 'Payable within 7 days of account date with interest at 10% per annum on all outstanding monies'
      }
    },
    enable: {
      _prefill: {
        value: true
      }
    },
    strike: {
      _prefill: {
        value: true
      }
    },
    marketingPaid: {
      _required: false
    },
    method: {
      _required: false
    },
    rendFreq: {
      _required: false
    },
    surcharge: {
      _required: false
    }
  },
  pool: {
    _required: false,
    present: {
      _required: false
    }
  },
  pressBudget: {
    _required: false,
    enable: {
      _required: false,
      _prefill: {
        value: true
      }
    },
    strike: {
      _prefill: {
        value: true
      }
    },
    additional: {
      _requiredIf: {
        expectations: [
          generateShortModeCondition(),
          { expectedValue: true, field: ['pressBudget', 'enable'] }

        ], modeOrTrueAndFalse: false
      }
    },
    start: {
      _requiredIf: {
        expectations: [
          generateShortModeCondition(),
          { expectedValue: true, field: ['pressBudget', 'enable'] }

        ], modeOrTrueAndFalse: false
      }
    },
    expenditure: {
      _requiredIf: {
        expectations: [
          generateShortModeCondition(),
          { expectedValue: true, field: ['pressBudget', 'enable'] }

        ], modeOrTrueAndFalse: false
      }
    },
    expPeriod: {
      _requiredIf: {
        expectations: [
          generateShortModeCondition(),
          { expectedValue: true, field: ['pressBudget', 'enable'] }

        ], modeOrTrueAndFalse: false
      }
    },
    endAgency: {
      _requiredIf: {
        expectations: [
          generateShortModeCondition(),
          { expectedValue: true, field: ['pressBudget', 'enable'] }

        ], modeOrTrueAndFalse: false
      }
    }
  },
  agentAuthority: {
    _required: true,
    _prefill: { value: true },
    _validationRequirementFailedMessage: 'Specify whether Agent has authority to accept offer on behalf of Vendor'
  },
  benefits: {
    _required: false,
    enable: {
      _required: true
    },
    benefits: {
      _minimumIf: { count: 1, expectedValue: true, field: ['benefits', 'enable'] },
      _children: {
        itemDesc: {
          _requiredIf: { expectedValue: true, field: ['benefits', 'enable'] }
        },
        itemSource: {
          _requiredIf: { expectedValue: true, field: ['benefits', 'enable'] }
        },
        itemCost: {
          _requiredIf: { expectedValue: true, field: ['benefits', 'enable'] }
        },
        itemRecipient: {
          _requiredIf: { expectedValue: true, field: ['benefits', 'enable'] }
        }
      }
    }
  },
  prevAgentEnable: {
    _required: false,
    _prefill: { value: false }
  },
  primaryVendor: {
    _required: false
  },
  professFee: {
    _required: false,
    fixedMode: {
      _requiredIf: { notOneOfExpectedValue: [FeeCalcMechanism.Set, FeeCalcMechanism.Range, FeeCalcMechanism.Discounted, FeeCalcMechanism.Scale], field: ['..', 'commissionMode'] }
    },
    commissionMode: {
      _requiredIf: { notOneOfExpectedValue: [FeeCalcMechanism.Set, FeeCalcMechanism.Range, FeeCalcMechanism.Discounted, FeeCalcMechanism.Scale], field: ['..', 'fixedMode'] }
    },
    commis: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Range, FeeCalcMechanism.Set, FeeCalcMechanism.Discounted], field: ['..', 'commissionMode'] }
    },
    commisUpper: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Range], field: ['..', 'commissionMode'] }
    },
    commisDiscount: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Discounted], field: ['..', 'commissionMode'] }
    },
    fixed: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Range, FeeCalcMechanism.Set, FeeCalcMechanism.Discounted], field: ['..', 'fixedMode'] }
    },
    fixedUpper: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Range], field: ['..', 'fixedMode'] }
    },
    fixedDiscount: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Discounted], field: ['..', 'fixedMode'] }
    },
    terms: {
      _prefill: {
        value: 'The fees are credited at settlement if a commission agreed is greater than the fees. Fees not being a commission are payable in any event, regardless of a settlement being achieved or not.'
      }
    },
    commisScale: {
      levels: {
        _minimumIf: { count: 2, expectedValue: FeeCalcMechanism.Scale, field: ['..', '..', 'commissionMode'] },
        _children: {
          limit: {
            _requiredIf: {
              expectations: [
                { evaluationFunction: 'notLastInGivenArray', field: ['..', '..'] },
                { expectedValue: FeeCalcMechanism.Scale, field: ['..', '..', '..', '..', 'commissionMode'] }
              ],
              modeOrTrueAndFalse: false
            }
          },
          amount: { _requiredIf: { expectedValue: FeeCalcMechanism.Scale, field: ['..', '..', '..', '..', 'commissionMode'] } }
        }
      }
    },

    fixedScale: {
      levels: {
        _minimumIf: { count: 2, expectedValue: FeeCalcMechanism.Scale, field: ['..', '..', 'fixedMode'] },
        _children: {
          limit: {
            _requiredIf: {
              expectations: [
                { evaluationFunction: 'notLastInGivenArray', field: ['..', '..'] },
                { expectedValue: FeeCalcMechanism.Scale, field: ['..', '..', '..', '..', 'fixedMode'] }
              ],
              modeOrTrueAndFalse: false
            }
          },
          amount: { _requiredIf: { expectedValue: FeeCalcMechanism.Scale, field: ['..', '..', '..', '..', 'fixedMode'] } }
        }
      }
    },
    fixedPayableWhen: {
      _requiredIf: { oneOfExpectedValue: [FeeCalcMechanism.Range, FeeCalcMechanism.Set, FeeCalcMechanism.Discounted, FeeCalcMechanism.Scale], field: ['..', 'fixedMode'] }
    }
  },
  saaTenant: {
    _required: false,
    tenantEnable: {
      _required: false,
      _prefill: { value: false }
    }
  },
  sale: {
    _required: false,
    advertPrc: {
      _required: false
    },
    advertPrcUpper: {
      _required: false
    },
    advertRange: {
      _required: true,
      _validationRequirementFailedMessage: 'Specify how price will be advertised'
    },
    agentEstPrc: {
      _required: true,
      _validationRequirementFailedMessage: 'Express price as a single figure'
    },
    saleMethod: {
      _required: true,
      _validationRequirementFailedMessage: 'Specify method of sale'
    },
    settlement: {
      _required: false,
      _prefill: { value: 30 }
    },
    vendorPrc: {
      _required: true,
      _validationRequirementFailedMessage: 'Express price as a single figure'
    }
  },
  saleAddrs: {
    _children: {
      _required: false,
      hundred: {
        _required: false
      },
      irrigationArea: {
        _required: false
      },
      lga: {
        _required: false
      },
      singleLine: {
        _required: true
      },
      streetAddr: {
        _required: true
      },
      subStateAndPost: {
        _required: true
      },
      titles: {
        _children: {},
        _minimum: 0
      }
    },
    _minimum: 0,
    _required: false
  },
  saleTitles: {
    _children: {
      _required: false,
      isWhole: {
        _required: true
      },
      title: {
        _required: true
      }
    },
    _required: false
  },
  titleDivision: {
    _required: false,
    depositChoice: {
      _required: false
    },
    proposedLots: {
      _required: false
    },
    plan: {
      _required: false
    }
  },
  searchFees: {
    _children: {
      _required: false,
      itemCost: {
        _required: false
      },
      itemDesc: {
        _required: false
      }
    },
    _prefill: {
      entries: [
        {
          itemDesc: 'Statutory & council searches'
        },
        {
          itemDesc: 'Form 1 preparation and certification'
        }
      ]
    },
    _required: false
  },
  specialTerms: {
    _required: false
  },
  uplift: {
    _required: false,
    enable: {
      _required: false
    },
    enablePrint: {
      _prefill: {
        value: true
      }
    },
    strike: {
      _prefill: {
        value: true
      }
    }
  },
  contractPrice: {
    inputTaxed: {
      _required: false
    }
  },
  vendorGst: {
    _required: false,
    _prefill: { value: false }
  },
  saaGstUnknowns: {
    vendorGst: { _required: false },
    inputTaxed: { _required: false }
  },
  vendors: {
    _children: merge(structuredClone(vendorChildRules),{
      _required: false,
      abn: {
        _required: false
      },
      addrSameAsSale: {
        _required: false
      },
      email1: {
        _required: false
      },
      inTrust: {
        _required: false
      },
      phone1: {
        _required: false
      },
      primarySubcontact: {
        _required: false
      },
      registeredOnTitle: {
        _required: false
      },
      mortgageNumber: {
        _required: false
      }
    }),
    _minimum: 1,
    _required: false
  },
  form1AndSearches: {
    _required: false,
    whoPays: {
      _required: false
    },
    whoSearches: {
      _required: false
    },
    epfOrderingAutomatic: {
      _requiredIf: { expectedValue: 'eckermanns', field: ['..', 'whoSearches'] }
    }
  }
};

export const saafieldGroups = {
  ...globalFieldGroups,
  'sale.agentEstPrc': 'advertRestrict',
  'sale.vendorPrc': 'advertRestrict',
  'sale.advertPrc': 'advertRestrict',
  'sale.advertPrcUpper': 'advertRestrict',
  'sale.saleMethod': 'advertRestrict', // The variation mode will be affected by this variable if in auction mode. So
  // because the function itself consumes this (and only one group function can be
  // run), we have to block it out here. When comes time for a conflict, in that
  // another group wants to modify it, a precedence of group functions will have
  // to be added, because the state of a previous group function may affect the
  // processing of subsequent functions.
  'contractPrice.depositPayAt': 'advertRestrict', // This group function now chains to setDepositTime
  'auctionFeeSwitches.applicable': 'advertRestrict', // As above, sale method now affects this as an output, so we need to tie it up. It'd be nice if we had it as a different function, but we don't have a mechanism for chaining updates
  'contractSpecial.hideFinance': 'advertRestrict',// Just because saleMethod is already tied up, this too shall be tied up
  'contractSpecial.hideSaleRequired': 'advertRestrict',
  'vendors.[].partyType': 'partyResetActivity',
  'vendors.[].authority': 'partyResetActivity',
  'vendors.[].inTrust': 'partyResetActivity',
  'vendors.[].registeredOnTitle': 'partyResetActivity',

  'vendors.[].namedExecutors.[].partyType': 'partyResetActivity',
  'vendors.[].namedExecutors.[].authority': 'partyResetActivity',

  'fixedSearchFees.epfFee.enable': 'saaSearchCostBind',
  'fixedSearchFees.councilSearch.enable': 'saaSearchCostBind',

  'professFee.fixedMode': 'setOneFeeMode',
  'professFee.commissionMode': 'setOneFeeMode',

  'professFee.fixed': 'swapFixedFeeRangeIfWrong',
  'professFee.fixedUpper': 'swapFixedFeeRangeIfWrong',
  'professFee.fixedDiscount': 'swapFixedFeeRangeIfWrong',

  'professFee.commis': 'swapCommissionFeeRangeIfWrong',
  'professFee.commisUpper': 'swapCommissionFeeRangeIfWrong',
  'professFee.commisDiscount': 'swapCommissionFeeRangeIfWrong',
  // Be mindful this function has outputs that go over the commis and fixed values above. However it seems to do
  // roughly the same thing, so I think I can get away with not making an aggregate function
  'professFee.enabledModes': 'setFeeDefaultSetupAndPatchUpNone', // Parent paths are now valid group functions

  'contractSettlement.onDate': 'settlementSetReset',
  'contractSettlement.onCondition': 'settlementSetReset',
  'contractSettlement.onContract': 'settlementSetReset',
  'contractSettlement.other': 'settlementSetReset',
  'contractSettlement.noPref': 'settlementSetReset', // Only applies in SAA, don't set up in contract
  ...StandardFieldGroupFnMaps.dueDateText
};
