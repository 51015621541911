import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormInstance, InstanceHistory, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import _ from 'lodash';
import { DocumentFieldType, PathSegments } from '@property-folders/contract/yjs-schema/model';
import { displayChangeSuffix, getValidationDefnByPath, hierarchyMap, isPathInHierarchy, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import {
  buildSigningTimelines,
  loadSnapshotFactory,
  maskVariationWithFeeDefaults,
  migrateSnapshotDataPreventFalsePositives,
  staticHistoryBuilderFactory
} from '@property-folders/common/util/dataExtract';
import { formCompleted } from '@property-folders/common/util/form/formCompleted';
import { DiffCollection } from '@property-folders/common/util/form/DiffCollection';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { Observable } from 'lib0/observable';
import { Y } from '@syncedstore/core';
import { useLightweightTransaction } from './useTransactionField';
import { useSelector } from 'react-redux';
import { NewlyAvailableFiles } from '@property-folders/common/redux-reducers/available-files';

type DiffCollectionPaths = {added: string[][], removed: string[][], updated: string[][]};

export class ExpandAllState extends Observable<'changed'> {
  private _value: boolean = false;

  set value(value: boolean) {
    if (value === this._value) return;
    this._value = value;
    this.emit('changed', [value]);
  }

  get value() {
    return this._value;
  }
}

export type LoadState =
  | 'pending'
  | 'done';

export type LineageContextType = {
  changeSet: DiffCollection|null;
  snapshotLoadError: string|null;
  variationsMode: boolean;
  expandAll?: ExpandAllState;
  snapshotData?: MaterialisedPropertyData;
  snapshotHistory?: InstanceHistory,
  loadState?: LoadState
  denormals?: FormInstance['unsignedDenormals']
};

export const lineageContextDefaultValue = {
  changeSet: null,
  snapshotLoadError: null,
  variationsMode: false
};

/* History of the lineage, and whether we're in variations mode
 *
 * We should not rely on the presence of a snapshot history to determine if we are in a variation
 */
export const LineageContext = createContext<LineageContextType>(lineageContextDefaultValue);

function mergeDiffObject(...diffCollection: DiffCollectionPaths[]): DiffCollectionPaths {
  return {
    added: diffCollection.map(diff=>diff.added).flat(),
    removed: diffCollection.map(diff=>diff.removed).flat(),
    updated: diffCollection.map(diff=>diff.updated).flat()
  };
}

function processCollection (pathCollections: DiffCollectionPaths): DiffCollection {
  return { added: hierarchyMap(pathCollections.added), removed: hierarchyMap(pathCollections.removed), updated: hierarchyMap(pathCollections.updated) };
}

export function getObjectDiff(original: any, updated: any, currentPath: PathSegments = [], transactionRules: DocumentFieldType): DiffCollectionPaths {
  let diffPaths: DiffCollectionPaths = { added: [], removed: [], updated: [] };
  // Items with _display suffix are not meaningful to the datamodel and should not be considered
  // a change
  const isDisplaySuffixed = currentPath.length && currentPath[currentPath.length-1].endsWith(displayChangeSuffix);
  // We've already got a convention of validation definition special bits not being prefixed with
  // underscores, so we'll just reuse that idea here, such that anything underscore prefixed is
  // never subject to variation checks
  const isUnderscorePrefixed = currentPath.length && currentPath[currentPath.length-1].startsWith('_');
  if (isDisplaySuffixed || isUnderscorePrefixed) {
    return diffPaths;
  }

  if (original == null && updated === '') {
    // This is a manually cleared string, where we had nothing set before. There are no children,
    // and this is not a change
    return diffPaths;
  }

  const validationDefn = getValidationDefnByPath(currentPath, transactionRules);
  // there are situations where the data model ends up in a change from `undefined` to { id: 'blah' }.
  // without the filter, this would appear to the user as though something in the variation has been modified,
  // but the user would not be able to see what has changed, since ids are not actually shown to the user.
  // `id` props are so pervasive in the model, that it makes sense to always filter them all out.
  // there's a similar rationale for other hidden props, so there is an allowance for ignoring them in the transaction rules.
  // BUT it turns out there are situations where the id field changing matters.
  if (validationDefn?._variationIgnore || (currentPath.at(-1) === 'id' && validationDefn?._variationIgnore === undefined)) {
    return diffPaths;
  }

  if (original != null && updated == null) {

    if (!validationDefn) {
      // console.warn('No defn! a', currentPath);
    }

    if (validationDefn?._type === 'Map') {
      updated = {};
    } else if (validationDefn?._type === 'Array') {
      updated = [];
    } else if (validationDefn?._type === 'boolean') {
      updated = false;
    } else {
      diffPaths.removed.push(currentPath);
      return diffPaths;
    }
  }
  // Don't consider a field that has been manually wiped with an empty string to be an added or updated value
  if (original == null && updated != null) {

    if (!validationDefn) {
      // console.warn('No defn! b', currentPath);
    }

    if (validationDefn?._type === 'Map') {
      original = {};
    } else if (validationDefn?._type === 'Array') {
      original = [];
    } else if (validationDefn?._type === 'boolean') {
      original = false;
    } else {
      diffPaths.added.push(currentPath);
      return diffPaths;
    }

  }

  if (
    typeof original != typeof updated
      || Array.isArray(original) !== Array.isArray(updated)

  ) {
    diffPaths.updated.push(currentPath);
    return diffPaths;
  }

  if (
    Array.isArray(original) &&
    Array.isArray(updated) &&
    original.filter(elem => !elem?.id).length === 0 &&
    updated.filter(elem => !elem?.id).length === 0
  ) {

    const mutableOriginal = [...original];
    const mutableUpdated = [...updated];

    const removedList: string[][] = [];
    const maybeUpdated = mutableOriginal.map(originalElem => {
      const id = originalElem.id;
      const thisPath = [...currentPath, `[${id}]`];
      const updatedElemIdx = _.findIndex(mutableUpdated, mu=>mu.id === id);
      let updatedElem = {};
      if (updatedElemIdx === -1) {
        removedList.push(thisPath);
      } else {
        updatedElem = mutableUpdated.splice(updatedElemIdx,1)[0];
      }

      return getObjectDiff(originalElem, updatedElem, thisPath, transactionRules);
    }).filter(Predicate.isNotNullish);
    const addedList: string[][] = mutableUpdated.map(elem=>[...currentPath, `[${elem.id}]`]);
    maybeUpdated.push(...mutableUpdated.map(added=>{
      return getObjectDiff({}, added, [...currentPath, `[${added.id}]`], transactionRules);
    }));

    if (maybeUpdated.every(({ added, removed, updated }) => !added.length && !removed.length && !updated.length)) {
      return diffPaths;
    }

    return mergeDiffObject(diffPaths, { added: addedList, removed: removedList, updated: [] }, ...maybeUpdated);
  }

  if (
    Array.isArray(original) &&
    Array.isArray(updated) &&
    getValidationDefnByPath(currentPath, transactionRules)?.['_subtype'] === 'StringSet'
  ) {
    const mutableOriginal = new Set<string>(original);
    const mutableUpdated = new Set<string>(updated);
    for (const elem of mutableUpdated) {
      if (mutableOriginal.has(elem)) {
        mutableUpdated.delete(elem);
        mutableOriginal.delete(elem);
        continue;
      }

    }
    return {
      added: [...mutableUpdated.values()].map(k=>[...currentPath, k]),
      removed: [...mutableOriginal.values()].map(k=>[...currentPath, k]),
      updated: []
    };
  }

  if (
    (original && typeof original === 'object')
    || (updated && typeof updated === 'object') // We check OR because we treat undefined like an
    // empty map
  ) {
    // Because this can also be a fallback for arrays, we need to key the paths correctly
    const noIdArrayMember = Array.isArray(original);
    const originalKeys = Object.keys(original);
    const updatedKeys =  Object.keys(updated);
    const allKeys = new Set([...originalKeys, ...updatedKeys]);

    const diffResult = [...allKeys.values()].map(key=>{
      return getObjectDiff(original[key], updated[key], [...currentPath, noIdArrayMember? `[${key}]` : key], transactionRules);
    }).filter(Predicate.isNotNullish);
    diffPaths = mergeDiffObject(diffPaths, { added: [], removed: [], updated: [] } ,...diffResult);
    return diffPaths;
  }

  // By this point equality should have some meaning because we've removed objects
  if (original != updated) {
    diffPaths.updated.push(currentPath);
    return diffPaths;
  }

  return diffPaths;
}

export function snapshotReadAndCallback(fileid: string) {
  return FileStorage.read(fileid).then(async response => {
    if (!response) {
      return 'No such snapshot in storage! ' + fileid;
    }
    const { data } = response;
    if (!data) {
      return 'Snapshot data not loaded!';
    }
    // Not sure this is the place to be modifying this in transit.
    return migrateSnapshotDataPreventFalsePositives(JSON.parse(await data.text()));
  });
}

export function useCurrentFormSnapshot() {
  const { formId, formName } = useContext(FormContext);
  const [propertyData, setPropertyData] = useState<MaterialisedPropertyData|null>(null);
  const { value: instance } = useLightweightTransaction<FormInstance|undefined>({ parentPath: normalisePathToStr(['formStates', formName, 'instances']), myPath: `[${formId}]`, bindToMetaKey: true });

  // TODO We don't have a snapshot before signing, but when editing we do. This is just a stub with
  // the key data to obtain the snapshot for the currently loaded form.
  //const [snapshot, setSnapshot];

  const fileId = instance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;

  useEffect(()=>{
    if (fileId) snapshotReadAndCallback(fileId).then(setPropertyData);
  },[fileId]);

  return { formCode: formName, propertyData };
}

export function isPathInAnyHierachy(path: string[], diffCol: DiffCollection | undefined | null) {
  if (!diffCol) {
    return false;
  }
  return Object.values(diffCol).map(modH => isPathInHierarchy(path, modH)).reduce((acc,cv)=>acc||cv);
}

export const loadSnapshot = loadSnapshotFactory(snapshotReadAndCallback);

export const staticHistoryBuilder = staticHistoryBuilderFactory(loadSnapshot);

function useIncrementIfFileBecomesAvailable(fileList: string[]) {
  const allAvailableIDs = useSelector(state => {
    const filesObj = state?.newlyAvailableFiles as NewlyAvailableFiles | undefined;
    if (!filesObj) return null;
    return filesObj;
  });
  const seenIds = useRef<{ seen: Set<string>, lastFlag: number }>({ seen: new Set(), lastFlag: 0 });
  return useMemo(() => {
    if (allAvailableIDs == null) return seenIds.current.lastFlag;
    let newSeen = false;
    for (const id of fileList) {
      if (!allAvailableIDs[id]?.available) continue;
      if (seenIds.current.seen.has(id)) continue;
      seenIds.current.seen.add(id);
      newSeen = true;
    }
    if (newSeen) seenIds.current.lastFlag++;
    return seenIds.current.lastFlag;
  }, [allAvailableIDs]);
}

function maskVariationWithDefaultsForNewFields(snapshot: MaterialisedPropertyData | undefined | null, currentData: MaterialisedPropertyData | undefined) {
  return maskVariationWithFeeDefaults(snapshot, currentData);
  // presumably if you had further things to process, you'd chain the output into the input of the next one
}

export function useVariation(requireAllHistory = false, overrides?: {
  transactionRules?: DocumentFieldType, // Allowing these to be used without a context. The default context will be ignored
  formCode?: string,
  ydoc?: Y.Doc,
  transactionMetaRootKey?: string,
  transactionRootKey?: string
}) {
  const docContext = useContext(YjsDocContext);
  const ydoc = overrides?.ydoc ?? docContext.ydoc;
  const transactionMetaRootKey = overrides?.transactionMetaRootKey ?? docContext.transactionMetaRootKey;
  const transactionRootKey = overrides?.transactionRootKey ?? docContext.transactionRootKey;

  const formContext = useContext(FormContext);
  const formCode = overrides?.formCode ?? formContext.formName;
  const transactionRules = overrides?.transactionRules ?? formContext.transactionRules;

  const { bindState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const formFamily = FormTypes[formCode]?.formFamily;
  const { data: formInstances } = bindState<FormInstance[]>(state => state?.formStates?.[formFamily]?.instances || []);
  const { bindState: bindStateData } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { data: propertyData } = bindStateData<MaterialisedPropertyData>(data=>data || {});

  const [latestSnapshotData, setLatestSnapshotData] = useState<MaterialisedPropertyData|null>(null);
  const [snapshotHistory, setSnapshotHistory] = useState<InstanceHistory|null>(null);
  const [snapshotLoadError, setSnapshotLoadError] = useState<string|null>(null);
  const [loadState, setLoadState] = useState<LoadState>('pending');

  const processedSnapshotData = useMemo(() => {
    return maskVariationWithDefaultsForNewFields(latestSnapshotData, propertyData);
  }, [latestSnapshotData, propertyData]);

  const signedInstances = useMemo(()=>{
    if (!formInstances) {
      return;
    }
    const sortedSignedInstances = formInstances?.filter(formCompleted);

    return (sortedSignedInstances.sort((instanceA, instanceB) => {
      // ascending sort
      return (instanceA.signing?.session?.completedTime||0) - (instanceB.signing?.session?.completedTime||0);
    }) as typeof formInstances);

  }, [formInstances?.length]);

  const latestInstance = useMemo(()=>{
    if (!Array.isArray(signedInstances)) {
      return;
    }

    return signedInstances[signedInstances.length-1];
  }, [signedInstances?.length]);

  const [missingFileIds, setMissingFileIds] = useState<string[]>([]);

  const reloadIncrementer = useIncrementIfFileBecomesAvailable(missingFileIds);

  useEffect(()=>{
    setLatestSnapshotData(null);
    setSnapshotLoadError(null);
    setSnapshotHistory(null);
    if (!latestInstance) {
      setSnapshotLoadError('No signed instances!');
      return;
    }
    const latestSnapshotId = latestInstance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;
    if (!latestSnapshotId) {
      setSnapshotLoadError('Latest instance has no associated Snapshot!');
      return;
    }

    setLoadState('pending');
    Promise.all(
      (signedInstances??[])
        .filter(instance=>requireAllHistory || instance.id === latestInstance?.id)
        .map(loadSnapshot)
    )
      .then(objectList => {
        for (const o of objectList) {
          if (o.error) {
            setMissingFileIds(ps => ([...ps, o.fileId]));
          }
        }
        const instHist = {
          instanceList: signedInstances??[],
          data: Object.assign({}, ...objectList.map(r=>r.success).filter(Predicate.isNotNullish))
        };
        const timelines = buildSigningTimelines(instHist);
        setSnapshotHistory({ ...instHist, signingTimelines: timelines.instanceSets, latestExpiry: timelines.latestExpiry || undefined });
        const latestInstanceFileId = (signedInstances?.find(inst=>inst.id === latestInstance.id)||{}).signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'';
        const latestError = objectList.find(o=>o.id === latestInstance.id)?.error;
        setLoadState('done');
        if (latestError) {
          setSnapshotLoadError(latestError);
        } else {
          setLatestSnapshotData(instHist.data[latestInstanceFileId]??null);
        }
      });

  }, [latestInstance, signedInstances?.length, requireAllHistory, reloadIncrementer]);

  const denormals = snapshotHistory?.signingTimelines && snapshotHistory.signingTimelines[snapshotHistory.signingTimelines.length-1]?.[0]?.unsignedDenormals;

  const changeSet = useMemo(()=>{
    const changeset = processedSnapshotData && propertyData && getObjectDiff(processedSnapshotData, propertyData, undefined, transactionRules);
    return changeset && processCollection(changeset);
  }, [processedSnapshotData, propertyData]);
  const rVal = useMemo(()=>({
    snapshotData: processedSnapshotData ?? undefined,
    snapshotHistory: snapshotHistory ?? undefined,
    changeSet: changeSet || null,
    snapshotLoadError: processedSnapshotData ? null : snapshotLoadError,
    denormals
  }), [changeSet, snapshotLoadError, processedSnapshotData, loadState, denormals]);
  return rVal;
}
