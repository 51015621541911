import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { prepareForCustomisingSmartForms, prepareForSigningSmartForms } from '../Wizard/prepareForSigningHandlers';
import { WizardDisplayContext, WizardDisplayContextType } from '@property-folders/components/context/WizardContexts';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  FormCodeUnion,
  FormInstance,
  FormSigningState,
  PropertyRootKey,
  MaterialisedPropertyData,
  RemoteSigningTypes,
  TransactionMetaData,
  CoverSheetMode,
  FormCode
} from '@property-folders/contract/yjs-schema/property';
import {
  SigningPartyConfiguration
} from '@property-folders/components/dragged-components/signing/SigningPartyConfiguration';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import {
  SigningGeneralConfiguration
} from '@property-folders/components/dragged-components/signing/SigningGeneralConfiguration';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import * as Y from 'yjs';
import { LineageContext } from '@property-folders/components/hooks/useVariation';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { propertyFolderSigning } from '@property-folders/contract/yjs-schema/model/field/property-folder-signing';
import { signingValidationRules } from '@property-folders/contract/yjs-schema/model/form/signing';
import { signingValidationRulesPortal } from '@property-folders/contract/yjs-schema/model/form/signing-portal';
import { FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { fullRevalidation, ValidationReducerState } from '@property-folders/common/redux-reducers/validation';
import { FormContextSigningOverride } from '@property-folders/components/form-gen-util/yjsStore';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { ComplexValidationContext } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { AuthApi } from '@property-folders/common/client-api/auth';
import {
  SigningConfigurationNextButton
} from '@property-folders/components/dragged-components/signing/SigningConfigurationNextButton';
import { useBrandConfig, useCurrentEntity, useEntities } from '@property-folders/components/hooks/useEntity';
import { footerCompanyName } from '@property-folders/common/util/formatting';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { usePdfWorker } from '@property-folders/components/hooks/usePdfWorker';
import { FormUserInteractionContext } from '@property-folders/components/context/FormUserInteractionContext';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormUserInteractionContextType } from '@property-folders/common/types/FormUserInteractionContextType';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { SigningOptions } from './SigningOptions';
import { YManagerContext } from '../../context/YManagerContext';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { spliceReadonlyData } from '@property-folders/common/util/patch-data-with-snapshot-by-path';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { Predicate } from '@property-folders/common/predicate';
import { DocumentFieldType } from '@property-folders/contract/yjs-schema/model';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { useFormFileRef } from '../../hooks/useFormFileRef';
import { SigningFieldsConfiguration } from './fieldConfiguration/SigningFieldsConfiguration';
import { AsyncButton } from '../AsyncButton';

export function SigningConfigurationInterceptor({ formId, documentRenderer, entityLogoLoadedUri, breadcrumbs, pdfDefinition }: TransactionFormProps & { documentRenderer: () => React.ReactElement, pdfDefinition: IPdfDefinitionProvider }) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { changeSet, snapshotData: previousData, snapshotHistory } = useContext(LineageContext);

  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(()=>yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);

  const { formName, formRules, transactionRules } = useContext(FormContext);
  const formCode = formName;
  const { ydoc, docName: propertyId, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { data: instance } = metaBindState<FormInstance>(m => FormUtil.getFormState(formCode, formId, m));
  const signingState = instance?.signing?.state || FormSigningState.None;
  const customiseScreen = instance?.signing?.customiseScreen;
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities[meta.entity.id] : null;
  const entityOfficialName = footerCompanyName(localEntity?.name, localEntity?.tradeName);
  const annexures = useMemo(()=>FormUtil.getAnnexures(formCode, formId, meta),[formCode, formId, meta]);
  const { instance: fileSync } = useContext(FileSyncContext);
  const formConfigs = useBrandConfig();
  const pdfWorker = usePdfWorker();
  const store = useStore();
  const currentEntity = useCurrentEntity();
  const customCover = instance?.cover?.mode === CoverSheetMode.Custom
    ? instance?.cover?.custom?.file
    : undefined;

  const { file: customCoverBlob, status: customCoverStatus } = useFormFileRef(
    customCover?.id,
    customCover?.code,
    Boolean(customCover));

  const getChangeSet = () => ({
    changes: changeSet,
    original: previousData,
    history: snapshotHistory
  });

  const cancelSigningHandler = () => {
    FormUtil.transitionSigningState({
      formCode,
      formId,
      dataBinder,
      metaBinder,
      sessionInfo,
      history: snapshotHistory,
      store
    }, {
      to: FormSigningState.None
    });
  };
  const prepareForSigningHandler = async () => {
    if (!pdfDefinition) {
      return;
    }
    if (!customCoverBlob && (customCoverStatus === 'loading' || customCoverStatus === 'error')) {
      return;
    }
    await prepareForSigningSmartForms({
      definitionProvider: pdfDefinition,
      property: spliceReadonlyData(formRules, previousData, dataBinder?.get(), transactionRules),
      meta: metaBinder?.get(),
      rootMeta: ydoc?.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData,
      formId,
      formCode,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      formConfigs,
      agencyName: entityOfficialName,
      pdfWorker,
      memberEntities,
      transitionConfig: { store, formCode, formId, metaBinder, dataBinder, history: snapshotHistory, sessionInfo, entitySigningOpts: localEntity?.signingOptions },
      getFormInstance: () => FormUtil.getFormState(formCode, formId, metaBinder?.get()),
      getUserPrefsData: getCurrentUserPrefs,
      store,
      ydoc,
      signingDefaultSettings: currentEntity?.signingOptions?.formSpecific?.[formCode as FormCode]
    },{
      fileSync,
      annexures,
      customCoverSheet: customCoverBlob,
      logoLocalUri: entityLogoLoadedUri,
      getChangeSet
    });
  };
  const prepareForSigningCustomHandler = async () => {
    if (!pdfDefinition) {
      return;
    }
    if (!customCoverBlob && (customCoverStatus === 'loading' || customCoverStatus === 'error')) {
      return;
    }
    await prepareForSigningSmartForms({
      definitionProvider: pdfDefinition,
      property: spliceReadonlyData(formRules, previousData, dataBinder?.get(), transactionRules),
      meta: metaBinder?.get(),
      rootMeta: ydoc?.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData,
      formId,
      formCode,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      formConfigs,
      agencyName: entityOfficialName,
      pdfWorker,
      memberEntities,
      transitionConfig: { store, formCode, formId, metaBinder, dataBinder, history: snapshotHistory, sessionInfo, entitySigningOpts: localEntity?.signingOptions },
      getFormInstance: () => FormUtil.getFormState(formCode, formId, metaBinder?.get()),
      getUserPrefsData: getCurrentUserPrefs,
      store,
      ydoc,
      signingDefaultSettings: currentEntity?.signingOptions?.formSpecific?.[formCode as FormCode]
    },{
      fileSync,
      annexures,
      customCoverSheet: customCoverBlob,
      logoLocalUri: entityLogoLoadedUri,
      getChangeSet,
      customised: true
    });
  };

  const prepareForCustomisingHandler = async () => {
    if (!pdfDefinition) {
      return;
    }
    if (!customCoverBlob && (customCoverStatus === 'loading' || customCoverStatus === 'error')) {
      return;
    }
    await prepareForCustomisingSmartForms({
      definitionProvider: pdfDefinition,
      property: spliceReadonlyData(formRules, previousData, dataBinder?.get(), transactionRules),
      meta: metaBinder?.get(),
      rootMeta: ydoc?.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData,
      formId,
      formCode,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      formConfigs,
      agencyName: entityOfficialName,
      pdfWorker,
      memberEntities,
      transitionConfig: { store, formCode, formId, metaBinder, dataBinder, history: snapshotHistory, sessionInfo, entitySigningOpts: localEntity?.signingOptions },
      getFormInstance: () => FormUtil.getFormState(formCode, formId, metaBinder?.get()),
      getUserPrefsData: getCurrentUserPrefs,
      store,
      ydoc,
      signingDefaultSettings: currentEntity?.signingOptions?.formSpecific?.[formCode as FormCode]
    },{
      fileSync,
      annexures,
      customCoverSheet: customCoverBlob,
      logoLocalUri: entityLogoLoadedUri,
      getChangeSet
    });
  };

  const backToGeneralScreenHandler = () => {
    metaBinder?.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (signing?.state !== FormSigningState.Configuring) return;

      delete signing.customiseScreen;
    });
  };

  if (!propertyId) {
    return <></>;
  }

  if (!ydoc) {
    return <></>;
  }

  if (signingState !== FormSigningState.Configuring) {
    return documentRenderer();
  }

  return <ContentTitler
    breadcrumbs={breadcrumbs}
    title={'Signing Configuration'}
    flex={true}
    afterBreadcrumbs={<></>}
    afterTitle={<div className='d-flex flex-row gap-2'>
      <Button
        variant="outline-secondary"
        onClick={cancelSigningHandler}>Cancel Signing</Button>
      {signingState === FormSigningState.Configuring && customiseScreen === 'fields' && <Button
        variant='outline-secondary'
        onClick={() => backToGeneralScreenHandler()}
      >Previous</Button>}
      {signingState === FormSigningState.Configuring && customiseScreen === 'fields' && <AsyncButton
        onClick={() => prepareForSigningCustomHandler()}
        processingLabel={'Preparing document'}
      >Submit for Signing</AsyncButton>}
    </div>}>
    <FormContextSigningOverride>
      {instance?.signing?.customiseScreen === 'fields'
        ? <SigningFieldsConfiguration
          propertyId={propertyId}
          formCode={formCode}
          formId={formId}
          entityLogoLoadedUri={entityLogoLoadedUri}
          ydoc={ydoc}
          yRootKey={transactionRootKey}
          yMetaRootKey={transactionMetaRootKey}
          prepareForSigning={prepareForSigningCustomHandler}
          prepareForCustomising={prepareForCustomisingHandler}
        />
        : <SigningConfiguration
          propertyId={propertyId}
          formCode={formCode}
          formId={formId}
          entityLogoLoadedUri={entityLogoLoadedUri}
          ydoc={ydoc}
          yRootKey={transactionRootKey}
          yMetaRootKey={transactionMetaRootKey}
          prepareForSigning={prepareForSigningHandler}
          prepareForCustomising={prepareForCustomisingHandler}
        />}
    </FormContextSigningOverride>
  </ContentTitler>;
}

export function useSigningValidation({
  ydoc,
  propertyId,
  formCode,
  formId,
  signingStatus,
  portalValidation,
  metaRootKey = PropertyRootKey.Meta,
  dataRootKeys = [PropertyRootKey.Data]
}: {
  ydoc?: Y.Doc,
  propertyId: string,
  formCode: string,
  formId: string,
  signingStatus: FormSigningState
  metaRootKey: string,
  dataRootKeys: string[]
  portalValidation?: boolean
}) {
  const dispatch = useDispatch();
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const docName = propertyId;
  const fields = useMemo(()=>{
    const rFields: DocumentFieldType = {
      ...propertyFolderSigning,
      _metaTree: { _type: 'Map', formStates: { _type: 'Map', [FormTypes[formCode].formFamily]: { _type: 'Map', instances: {
        _type: 'Array',
        _children: {
          _type: 'Map',
          signing: { _type: 'Map', parties: {
            _type: 'Array',
            _children: {
              _type: 'Map',
              proxyName: { _type: 'string' },
              proxyAuthority: { _type: 'number', _label: 'Signer Type', _subtype: 'enum_SignerProxyType' },
              proxyPhone: { _type: 'string' },
              proxyEmail: { _type: 'string' }
            }
          } }
        }
      } } } }
    };
    return rFields;
  }, [FormTypes[formCode].formFamily]);
  useEffect(() => {
    // try to set up validation rules for the configuration screen
    if (!ydoc) return;
    if (!propertyId) return;
    if (signingStatus !== FormSigningState.Configuring) return;

    const formRules = portalValidation ? signingValidationRulesPortal : signingValidationRules;

    // [meta1, data1], [meta1, data2] = meta1 should trigger validation for data1 and data2
    const currentDocMaps = new Map(dataRootKeys
      .map(k => [k, ydoc.getMap(k)]));
    const currentMetaMap = ydoc.getMap(metaRootKey);
    const getComplexValidationContext = (): ComplexValidationContext => {
      const meta = currentMetaMap.toJSON() as TransactionMetaData;
      return {
        signing: FormUtil.getSigning(formCode, formId, meta),
        formCode,
        formId,
        formPath: FormUtil.getFormPath(formCode, formId),
        propertyMeta: meta,
        entities: memberEntities
          ? Object.values(memberEntities).filter(Predicate.isNotNull)
          : undefined
      };
    };
    const changeFunctions = new Map(dataRootKeys
      .map(dataRootKey => [dataRootKey, () => {
        const currentDocMap = currentDocMaps.get(dataRootKey)!;
        dispatch(fullRevalidation({
          docName,
          mapRoot: dataRootKey,
          formName: formCode,
          dataTree: currentDocMap.toJSON(),
          transactionFieldsDefn: fields,
          formRules,
          context: getComplexValidationContext()
        }));
      }]));
    const everythingChangeFunction = () => {
      for (const [_, changeFunction] of changeFunctions) {
        changeFunction();
      }
    };

    dataRootKeys.forEach(k => {
      const currentDocMap = currentDocMaps.get(k);
      const changeFunction = changeFunctions.get(k);
      if (!currentDocMap) return;
      if (!changeFunction) return;
      currentDocMap.observeDeep(changeFunction);
    });
    currentMetaMap.observeDeep(everythingChangeFunction);

    try {
      everythingChangeFunction();
    } catch (err: unknown) {
      console.error(err);
    }

    return () => {
      dataRootKeys.forEach(k => {
        const currentDocMap = currentDocMaps.get(k);
        const changeFunction = changeFunctions.get(k);
        if (!currentDocMap) return;
        if (!changeFunction) return;
        currentDocMap.unobserveDeep(changeFunction);
      });
      currentMetaMap.unobserveDeep(everythingChangeFunction);
    };
  }, [ydoc, propertyId, formCode, memberEntities]);
}

export interface SigningConfigurationProps {
  propertyId: string,
  formCode: string,
  formId: string,
  ydoc?: Y.Doc,
  yRootKey: string | undefined,
  yMetaRootKey: string | undefined,
  entityLogoLoadedUri?: string,
  prepareForSigning: () => Promise<void>,
  prepareForCustomising?: (() => Promise<void>) | (() => void),
  showColours?: boolean;
}

export function SigningConfiguration({
  propertyId,
  formCode,
  formId,
  ydoc,
  yRootKey = PropertyRootKey.Data,
  yMetaRootKey = PropertyRootKey.Meta,
  entityLogoLoadedUri,
  prepareForSigning,
  prepareForCustomising,
  showColours = false
}: SigningConfigurationProps) {
  const { snapshotData: previousData } = useContext(LineageContext);
  const { value: instance, fullPath: instancePath } = useLightweightTransaction<FormInstance>({ myPath: FormUtil.getFormPath(formCode, formId), bindToMetaKey: true });
  const partySublineageIds = (instance?.signing?.parties || [])
    .map(p => p.source.sublineageId)
    .filter(Predicate.isNotNull);
  const allDataRootKeys = [...new Set([yRootKey].concat(partySublineageIds))];
  const focusErrList = useSelector((state: { validation: ValidationReducerState }) => {
    const result: string[] = [];
    for (const rootKey of allDataRootKeys) {
      const items = state?.validation?.focusErrList?.[propertyId ?? '']?.[rootKey]?.[formCode] || [];
      result.push(...items);
    }
    return result;
  });

  const wizardDisplayContextState = useMemo<WizardDisplayContextType>(()=>({
    showFocusErrors: true,
    focusErrList: focusErrList || []
  }), [focusErrList]);
  const {
    showGeneralEmailConfig,
    signingOrderVersion,
    partyGroups
  } = useSigningNavProps({ signing: instance?.signing, formCode });
  useSigningValidation({
    ydoc,
    propertyId,
    formCode,
    formId,
    signingStatus: FormSigningState.Configuring,
    dataRootKeys: allDataRootKeys,
    metaRootKey: yMetaRootKey
  });

  const userInteraction = useMemo<FormUserInteractionContextType>(()=>({
    userShouldSeeAllValidation: true
  }),[]);

  const waitingForRemoteInfo = Boolean();

  if (!ydoc) {
    return <></>;
  }

  /**
   * Situations:
   * * Basic - No Customisation (e.g. Subscription Docs?)
   *   * From Configuration Screen: [Prepare for Signing]
   * * Customisation Required (e.g. uploaded docs)
   *   * From Configuration Screen: [Continue] (to customisation, set to fields configuration)
   *   * From Fields Screen [Prepare: for Signing]
   * * Customisation Optional (e.g. RSAA)
   *   * From Configuration Screen: [Prepare for Signing, Customise Fields]
   *   * From Fields Screen: [Prepare for Signing]
   */
  const hasRemoteSigning = !!instance?.signing?.parties?.find(p => RemoteSigningTypes.includes(p.type));
  const isCustomisable = FormTypes[formCode]?.isCustomisable;
  const isUploadedSubscriptionDocument = Boolean(formCode === FormCode.UploadedDocument && instance?.subscription?.documentId);
  const nextButton = <SigningConfigurationNextButton
    actions={[(!isCustomisable || isCustomisable === 'optional' || isUploadedSubscriptionDocument || instance?.signing?.customiseScreen === 'fields') ? {
      fn: prepareForSigning,
      label: 'Submit for Signing',
      waitingLabel: 'Preparing document'
    } : undefined, isCustomisable && prepareForCustomising ? {
      fn: () => prepareForCustomising(),
      label: 'Customise Signing Fields',
      waitingLabel: 'Preparing document'
    } : undefined]}
  />;

  // future: look into <WizardFieldFocusStateContext.Provider value={fieldFocusContextSetState}>
  return <WizardDisplayContext.Provider value={wizardDisplayContextState}>
    <FormUserInteractionContext.Provider value={userInteraction}>
      <div className="overflow-auto h-100">
        <Form className='wizard-form' autoComplete='off' noValidate
          style={{ maxWidth: '1320px', marginInline: 'auto' }}>
          <SigningOptions
            key='configuration-signing-options'
            hasRemoteSigning={hasRemoteSigning}
            instancePath={instancePath}
            formCode={formCode as FormCodeUnion}
          />
          <SigningPartyConfiguration
            key='configuration-pseudo-collection'
            name='configuration-pseudo-collection'
            previousData={previousData}
            formCode={formCode as FormCodeUnion}
            formId={formId}
            ydoc={ydoc}
            yRootKey={yRootKey}
            yMetaRootKey={yMetaRootKey}
            partyGroups={partyGroups}
            signingOrderVersion={signingOrderVersion}
            instancePath={instancePath}
            showColours={showColours}
            waitingForRemoteInfo={waitingForRemoteInfo}
          />
          {showGeneralEmailConfig
            ? <WizardStepPage
              key='signing-config'
              name='signing-config'
              label='Message'
              icon='settings'>
              <SigningGeneralConfiguration
                formCode={formCode}
                formId={formId}
                entityLogoLoadedUri={entityLogoLoadedUri}
                nextButton={nextButton}
              />
            </WizardStepPage>
            : <div className={'signing-configuration'}>
              <Row className={'mx-0 mx-sm-2 mx-md-4 p-3'}>
                <Col className={'d-flex flex-row-reverse'}>
                  {nextButton}
                </Col>
              </Row>
            </div>}
        </Form>
      </div>
    </FormUserInteractionContext.Provider>
  </WizardDisplayContext.Provider>;
}
