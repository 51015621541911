import { globalFieldGroups } from '../field';
import { ValidationDefnType } from '../validation-field';
import { StandardFieldGroupFnMaps } from '../field-group-function';
import { merge } from 'lodash';
import { vendorChildRules, purchaserChildRules } from './common-rules';

export const saleContractFormRules: ValidationDefnType = {
  _atLeastDefns: [{
    focusTarget: 'property-section',
    fields: [['','saleAddrs', '[0]', 'streetAddr'], '.saleTitles.[0].title']
  }],
  cgtEnable: {
    _required: true
  },
  exclusions: {
    _required: true,
    simple: {
      _required: true
    }
  },
  chattels: {
    _required: true,
    cccEnable: {
      _required: false
    },
    simple: {
      _required: true
    }
  },
  contractOther: {
    _required: false,
    enable: {
      _required: false
    }
  },
  vendorGst: {
    _required: true
  },
  contractPrice: {
    _required: false,
    deposit: {
      _required: false
    },
    depositPayAt: {
      _required: false
    },
    inputTaxed: {
      _requiredIf: { expectedValue: true, field: ['..', '..', 'vendorGst' ] }
    },
    purchasePrice: {
      _required: true
    }
  },
  contractSchedule: {
    _required: false,
    noForm1NoCoolMatters: {
      _required: false
    }
  },
  contractSettlement: {
    _required: false,
    date: {
      _required: false,
      _requiredIf: {
        expectations: [
          { expectedValue: true, field: ['contractSettlement', 'onDate'] },
          { expectedValue: true, field: ['..', 'enabledModes', 'date'], defaultValueIfNullish: true }
        ],
        modeOrTrueAndFalse: false
      }
    },
    onConditionDays: {
      _required: false,
      _requiredIf: {
        expectations: [
          { expectedValue: true, field: ['contractSettlement', 'onCondition'] },
          { expectedValue: true, field: ['..', 'enabledModes', 'condition'], defaultValueIfNullish: true }
        ],
        modeOrTrueAndFalse: false
      }
    },
    onContractDays: {
      _required: false,
      _requiredIf: {
        expectations: [
          { expectedValue: true, field: ['contractSettlement', 'onContract'] },
          { expectedValue: true, field: ['..', 'enabledModes', 'contract'],  defaultValueIfNullish: true }
        ],
        modeOrTrueAndFalse: false
      }
    },
    otherText: {
      _required: false,
      _requiredIf: {
        expectations: [
          { expectedValue: true, field: ['contractSettlement', 'other'] },
          { expectedValue: true, field: ['..', 'enabledModes', 'other'], defaultValueIfNullish: false }
        ],
        modeOrTrueAndFalse: false
      }
    },
    onDate: {
      _prefill: {
        value: true
      },
      _required: false
    },
    onCondition: {
      _required: false
    },
    other: {
      _required: false
    },
    onContract: {
      _required: false
    }
  },
  contractSpecial: {
    // Not required because we don't show this field anymore
    _required: false,
    financeDeadlineTime: {
      _prefill: {
        value: '17:00'
      }
    },
    financeRequired: {
      _required: false
    },
    purchaserSaleRequired: {
      _required: false
    }
  },
  encroach: {
    _required: false
  },
  encroachEnable: {
    _required: false
  },
  gstWithholdEnable: {
    _required: false
  },
  notiWorks: {
    _required: false
  },
  notiWorksEnable: {
    _required: false
  },
  vendorWorks: {
    _required: false
  },
  vendorWorksEnable: {
    _required: false
  },
  pool: {
    _required: false,
    present: {
      _required: false
    }
  },
  primaryPurchaser: {
    _required: false
  },
  primaryVendor: {
    _required: false
  },
  assocAgents: {
    _required: false,
    _children: {
      company: { _requiredIf: { expectations: [
        { expectedValue: true, field: ['authAssoc'] },
        { evaluationFunction: 'anySalespersonName', field: ['..', 'salesp'] }
      ] } }
    }
  },
  purchasers: {
    _children: merge(structuredClone(purchaserChildRules),{
      _required: false,
      abn: {
        _required: false
      },
      addressSingleLine: {
        _required: false
      },
      addrSameAsPrimary: {
        _required: false
      },
      email1: {
        _required: false
      },
      fullLegalName: {
        _required: true
      },
      inTrust: {
        _required: false
      },
      phone1: {
        _required: false
      },
      primarySubcontact: {
        _required: false
      }
    }),
    _required: false,
    _minimum: 1
  },
  saaTenant: {
    _required: false,
    tenantEnable: {
      _required: false
    }
  },
  saleAddrs: {
    _children: {
      _required: false,
      hundred: {
        _required: false
      },
      irrigationArea: {
        _required: false
      },
      lga: {
        _required: false
      },
      singleLine: {
        _required: true
      },
      streetAddr: {
        _required: true
      },
      subStateAndPost: {
        _required: true
      },
      titles: {
        _children: {},
        _minimum: 0
      }
    },
    _minimum: 0,
    _required: false
  },
  saleTitles: {
    _children: {
      _required: false,
      isWhole: {
        _required: true
      },
      title: {
        _required: true
      }
    },
    _required: false
  },
  titleDivision: {
    _required: false,
    depositChoice: {
      _requiredIf: { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] }
    },
    hasDevelopmentAuthorisation: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { oneOfExpectedValue: ['commDiv', 'landDiv'], field: ['titleDivision', 'depositChoice'] }
      ], modeOrTrueAndFalse: false }
    },
    planDue12: {
      _required: false
    },
    planDueDate: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { oneOfExpectedValue: ['commDiv', 'landDiv'], field: ['titleDivision', 'depositChoice'] },
        { notExpectedValue: true, field: ['titleDivision', 'planDue12'] }
      ], modeOrTrueAndFalse: false }
    },
    proposedLots: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { oneOfExpectedValue: ['commDiv', 'landDiv'], field: ['titleDivision', 'depositChoice'] }
      ], modeOrTrueAndFalse: false }
    },
    plan: {
      id: {
        _requiredIf: {
          expectations: [
            { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
            { oneOfExpectedValue: ['commDiv', 'landDiv'], field: ['titleDivision', 'depositChoice'] }
          ], modeOrTrueAndFalse: false
        }
      }
    }
  },
  dwelling: {
    _required: false,
    vacantLand: {
      _requiredIf: { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] }
    },
    demolitionRequired: {
      _requiredIf: { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] }
    },
    purchaserWillBuild: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { expectedValue: true, field: ['dwelling', 'vacantLand'] }
      ], modeOrTrueAndFalse: false }
    },
    purchaserBuilderName: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { expectedValue: true, field: ['dwelling', 'vacantLand'] },
        { expectedValue: true, field: ['dwelling', 'purchaserWillBuild'] }
      ], modeOrTrueAndFalse: false }
    },
    buildContractDue12: {
      _required: false
    },
    buildContractDueDate: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { expectedValue: true, field: ['dwelling', 'vacantLand'] },
        { expectedValue: true, field: ['dwelling', 'purchaserWillBuild'] },
        { notExpectedValue: true, field: ['dwelling', 'buildContractDue12'] }
      ], modeOrTrueAndFalse: false }
    },
    vendorWillBuild: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { expectedValue: false, field: ['dwelling', 'vacantLand'] }
      ], modeOrTrueAndFalse: false }
    },
    vendorHasPlanningConsent: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { expectedValue: false, field: ['dwelling', 'vacantLand'] },
        { expectedValue: true, field: ['dwelling', 'vendorWillBuild'] }
      ], modeOrTrueAndFalse: false }
    },
    vendorHasDevelopmentApproval: {
      _requiredIf: { expectations: [
        { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
        { expectedValue: false, field: ['dwelling', 'vacantLand'] },
        { expectedValue: true, field: ['dwelling', 'vendorWillBuild'] }
      ], modeOrTrueAndFalse: false }
    },
    vendorPlan: {
      id: {
        _requiredIf: {
          expectations: [
            { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
            { expectedValue: false, field: ['dwelling', 'vacantLand'] },
            { expectedValue: true, field: ['dwelling', 'vendorWillBuild'] }
          ], modeOrTrueAndFalse: false
        }
      }
    },
    vendorSpecification: {
      id: {
        _requiredIf: {
          expectations: [
            { evaluationFunction: 'explicitTitlePortions', field: ['saleTitles'] },
            { expectedValue: false, field: ['dwelling', 'vacantLand'] },
            { expectedValue: true, field: ['dwelling', 'vendorWillBuild'] }
          ], modeOrTrueAndFalse: false
        }
      }
    }
  },
  vendors: {
    _children: merge(structuredClone(vendorChildRules),{
      _required: false,
      abn: {
        _required: false
      },
      addrSameAsSale: {
        _required: false
      },
      namedExecutors: {
        _children: {
          email1: { _ignoreVariationFlag: true },
          email2: { _ignoreVariationFlag: true },
          phone1: { _ignoreVariationFlag: true },
          phone2: { _ignoreVariationFlag: true },
          legalRepresentatives: {
            _children: {
              email: { _ignoreVariationFlag: true },
              phone: { _ignoreVariationFlag: true }
            }
          }
        }
      },
      email1: {
        _required: false,
        _ignoreVariationFlag: true
      },
      email2: { _ignoreVariationFlag: true },
      phone1: {
        _required: false,
        _ignoreVariationFlag: true
      },
      phone2: { _ignoreVariationFlag: true },
      legalRepresentatives: {
        _children: {
          email: { _ignoreVariationFlag: true },
          phone: { _ignoreVariationFlag: true }
        }
      },
      inTrust: {
        _required: false
      },
      primarySubcontact: {
        _required: false
      },
      registeredOnTitle: {
        _required: false
      },
      mortgageNumber: {
        _required: false
      }
    }),
    _minimum: 1,
    _required: false
  },
  coolingOff: {
    purchaserRight: { _required: true }
  }
};

export const fieldGroups = {
  ...globalFieldGroups,
  'contractPrice.purchasePrice': 'purchasePriceGst',
  'contractPrice.purchaseGst': 'purchasePriceGst',

  'vendors.[].partyType': 'partyResetActivity',
  'vendors.[].authority': 'partyResetActivity',
  'vendors.[].inTrust': 'partyResetActivity',
  'vendors.[].registeredOnTitle': 'partyResetActivity',

  'vendors.[].namedExecutors.[].partyType': 'partyResetActivity',
  'vendors.[].namedExecutors.[].authority': 'partyResetActivity',

  'purchasers.[].partyType': 'partyResetActivity',
  'purchasers.[].authority': 'partyResetActivity',
  'purchasers.[].inTrust': 'partyResetActivity',
  'purchasers.[].registeredOnTitle': 'partyResetActivity',

  'primaryPurchaser': 'purchaserAddrSameAsPrimary',
  'purchasers.[].addressSingleLine': 'purchaserAddrSameAsPrimary',
  'purchasers.[].addrSameAsPrimary': 'purchaserAddrSameAsPrimary',
  ...StandardFieldGroupFnMaps.dueDateText
};
